@use "sass-svg-uri";

/* Glide styles */

// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.glide__arrow {
  position: absolute;
  display: block;
  top: 46%;
  z-index: 2;
  text-transform: uppercase;
  padding: 9px;
  border: none;
  color: white;
  background-color: black;
  border-radius: 50%;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
  outline: none;
  transition: background-color 0.3s ease-out;

  &--right {
    right: 50px;
  }

  &--left {
    left: 90px;
  }

  @media (max-width: 1000px) {
    &--right {
      right: 10px;
    }
  
    &--left {
      left: 10px;
    }
  }

  svg {
    height: 30px;
    width: 30px;
  }

  &:hover {
    background-color: saturate(#61a1a6, 25%);
  }
}

$heading1: 26px;
$heading2: 22px;
$heading3: 20px;
$body: 18px;
$caption: 16px;


@keyframes slideDown {
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideUp {
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInDown {
  animation: fadeIn 1.5s linear, slideDown 2s cubic-bezier(0, 0, 0, 1);
  animation-fill-mode: both;
}

.fadeInUp {
  animation: fadeIn 1.5s linear 1s, slideUp 1.5s cubic-bezier(0, 0, 0, 1) 1s;
  animation-fill-mode: both;
}

.fadeIn {
  animation: fadeIn 1.5s linear;
  animation-fill-mode: both;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: $body;
  position: relative;
}

.hidden {
  display: none;
}

#wheel {
  position: fixed;
  top: 10px;
  left: 10px;
  height: 55px;
  width: 55px;
  z-index: 3;
  transition: transform 0.25s linear;
  filter: drop-shadow(0 0 5px #FFF);

  @media (max-width: 1000px) {
    & {
      transition: none;
    }
  }
}

#lane {
  position: absolute;
  width: 0px;
  left: 36px;
  top: 36px;
  bottom: 36px + 72px; // to account for footer
  opacity: 0.5;
  border-left: 2px dotted black;
  z-index: 1;
  box-shadow: 0px 0px 7px 1px white;

  &::before, &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    left: -6px;
    background-color: black;
    border-radius: 50%;
  }

  &::before {
    top: -5px;
  }

  &::after {
    bottom: -5px;
  }
}

@media (max-width: 1000px) {
  
  #lane {
    display: none;
  }
  
}

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;

  .content {
    padding: 60px 30px 60px 90px;
    box-sizing: border-box;
  }

  @media (max-width: 1000px) {
    .content {
      padding: 60px 30px;
    }  
  }
}

$slanted-height: 10vh;

.raised-section {
  min-height: 80vh;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: #efefef;
  margin: $slanted-height 0;

  .title {
    top: -$slanted-height;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
  }

  @media (max-width: 1000px) {
    & {
      margin: $slanted-height+5 0;
  
      .title {
        top: -$slanted-height - 5;
  
        &::before {
          display: none;
        }
      }
    }  
  }

  &:before, &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: $slanted-height;
    z-index: -1;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
  }

  &:before {
    top: calc(-#{$slanted-height} + 2px);
    background-image: sass-svg-uri.encode('<svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 500 500" fill="#efefef"><polygon points="0,500 500,0 500,500"/></svg>');
  }

  &:after {
    bottom: calc(-#{$slanted-height} + 2px);
    background-image: sass-svg-uri.encode('<svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 500 500" fill="#efefef"><polygon points="0,0 0,500 500,0"/></svg>');
  }
}

.title {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 8px;
  font-size: $heading2;
  padding-left: 90px;
  margin: 0;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    left: 32px;
    top: calc(50% - 5px);
    background-color: #979797;
    border-radius: 50%;
    z-index: 2;
  }

  @media (max-width: 1000px) {
    & {
      text-align: center;
      padding-left: 0;
    }
    &::before {
      display: none;
    }
  }
}

.audio-book-strip{
  color: #efd48c;
  background: #313d49;
  text-align: center;
  padding: 26px;
}

// specific sections

.webp .section-intro {
  background-image: url('img/band-promo5.webp');
}

.no-webp .section-intro {
  background-image: url('img/band-promo5.jpg');
}

.section-intro {
  background-color: #000;
  background-size: cover;
  background-position-x: center;
  // pesky chrome error that leaces 1px if we use bottom
  background-position-y: 99.5%;
  background-repeat: no-repeat;
  height: 140vh;
  align-items: center;

  .logo {
    width: 300px;
    padding-top: 90px;
    margin: 0;
    // filter: drop-shadow(0 0 15px #FFF) drop-shadow(0 0 25px #FFF);
  }

  .subtitle {
    color: #efefef;
    text-transform: uppercase;
    font-size: $heading2;
    letter-spacing: 10px;
    font-weight: 900;
    margin: 0;
    padding-top: 35px;
    // text-shadow: 0 0 5px #FFF, 0 0 10px #FFF; //, 0 0 15px #FFF, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 55px #fff, 0 0 75px #fff;
  }

  @media (max-width: 1000px) and (orientation: portrait) {
    & {
      height: 90vh;
      // background-position-y: 40px;
    }
  }

  @media (max-width: 823px) and (orientation: landscape) {
    & {
      height: 165vh;
      .logo {
        padding-top: 0;
      }
    }
  }
}

.section-video {

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .embed-container {
    background-color: black;
    box-shadow: 0px 0px 27px 0px rgba(0,0,0,1);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    width: 70%;
    height: 0;
    padding-bottom: 30%;
  }

  @media (max-width: 1000px) {
    .content {
      padding-left: 0;
      padding-right: 0;
    }
    .embed-container {
      position: relative;
      padding-bottom: 41%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      width: 100%;
      border-radius: 0;
    }
  }

  .embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.section-live {
  // background-color: #fff;
  padding-top: 40px;

  .events {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1200px) {
      align-items: start;
    }

    @media (max-width: 1000px) {
      align-items: center;
    }
    
    .event {
      display: flex;
      align-items: center;
      font-size: $heading3;
      text-transform: uppercase;
      margin-bottom: 20px;
      text-align: center;

      .name {
        font-weight: 900;
        letter-spacing: 6px;
        margin-left: 40px;
      }

      .country {
        margin-left: 40px;
        font-size: smaller;
      }

      .more-info {
        margin-left: 40px;
        text-decoration: none;
        color: #fff;
        background-color: #000;
        padding: 10px;
        transition: background-color 0.3s ease-out;
        font-size: smaller;

        &:hover {
          background-color: #61a1a6;
        }
      }

      

      @media (max-width: 1000px) {
        & {
          flex-direction: column;
          
          .name, .country, .more-info {
            margin-left: 0;
          }

          .more-info {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .soon, .gigstarter-button {
    text-align: center;
  }
}

.product-wrapper {

  .product {
    position: relative;
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .product-image {
      max-height: 65vh;
      max-width: 100%;
    }

    .product-ui {
      position: absolute;
      bottom: 0;
      padding-bottom: 50px;
      right: 0;

      .name {
        text-transform: uppercase;
        font-size: $heading3;
        // font-family: 'Source Serif Pro', serif;
        letter-spacing: 5px;
        margin-bottom: 30px;
        padding-right: 50px;
      }

      .description {
        margin-bottom: 30px;
        padding-right: 50px;

        p {
          margin: 0 0 7px 0;
        }
      }

      .buy-button {
        box-sizing: border-box;
        display: block;
        width: 100%;
        font-size: $heading1;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 5px;
        padding: 15px;
        color: white;
        text-decoration: none;
        box-shadow: none;
        transition: all 0.3s ease-out;
        background-color: #61a1a6;

        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        &:hover {
          box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.4);
          background-color: saturate(#61a1a6, 25%);
        }
      }

      @media (max-width: 1000px) {
        & {
          position: static;
          padding: 10px 15px 50px 15px;

          .name, .description {
            padding-right: 0;
          }

          .name, .description, .buy-button {
            text-align: center;
          }
        }
      }
    }

    .product-ui2 {

      padding: 10px 15px 50px 15px;
      
      .description {
        margin-bottom: 30px;
        padding: 10px;
        text-align: center;

        p {
          margin: 0 0 7px 0;
        }
      }

      .action-button {
        box-sizing: border-box;
        display: block;
        text-align: center;
        width: 100%;
        font-size: $heading1;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 5px;
        padding: 15px;
        color: #efd48c;
        background-color: #353f53;
        text-decoration: none;
        box-shadow: none;
        transition: all 0.3s ease-out;
        border-radius: 99999px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        &:hover {
          box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.4);
          background-color: saturate(#5a6577, 25%);
        }
      }
    }
  }
}

.section-contact {

  .content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 500px;

    label {
      margin-bottom: 6px;
      text-transform: uppercase;
      font-size: $caption;
      font-weight: 900;
    }

    input, textarea {
      font-family: inherit;
      border: 2px solid black;
      padding: 8px;
      margin-bottom: 18px;
      font-size: $body;
      outline: none;
      transition: border 0.3s ease-in-out;

      &:focus {
        border: 2px solid #61a1a6;
      }

      &:invalid.touched {
        border: 2px solid red;
      }
    }

    textarea {
      resize: vertical;
    }

    button {
      appearance: none;
      border: none;
      background-color: black;
      color: white;
      text-transform: uppercase;
      font-size: $heading2;
      font-weight: 400;
      letter-spacing: 5px;
      text-align: center;
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;
      padding: 8px;

      &[disabled] {
        background-color: #ccc;
        cursor: not-allowed;
      }

      &:not([disabled]):hover, &:not([disabled]):focus {
        background-color: #61a1a6;
        outline: none;
      }
    }
  }
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  background-color: #000;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.4);

  .social-link {
    font-size: $body;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 24px;
    display: flex;
    text-decoration: none;
    color: inherit;
    margin: 0 24px 0 0;
    color: #fff;
    transition: color 0.3s ease-out;

    &:last-child {
      margin: 0;
    }

    img, svg, object {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }

    &:hover {
      color: #61a1a6;
    }
  }

  @media (max-width: 1000px) {
    & {
      flex-direction: column;
      .social-link {
        margin: 0 0 16px 0;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}