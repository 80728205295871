.glide {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  z-index: 2;
  text-transform: uppercase;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  background-color: #000;
  border: none;
  border-radius: 50%;
  outline: none;
  padding: 9px;
  line-height: 1;
  transition: background-color .3s ease-out;
  display: block;
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__arrow--right {
  right: 50px;
}

.glide__arrow--left {
  left: 90px;
}

@media (max-width: 1000px) {
  .glide__arrow--right {
    right: 10px;
  }

  .glide__arrow--left {
    left: 10px;
  }
}

.glide__arrow svg {
  height: 30px;
  width: 30px;
}

.glide__arrow:hover {
  background-color: #42bbc5;
}

@keyframes slideDown {
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideUp {
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInDown {
  animation-name: fadeIn, slideDown;
  animation-duration: 1.5s, 2s;
  animation-timing-function: linear, cubic-bezier(0, 0, 0, 1);
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-play-state: running, running;
  animation-delay: 0s, 0s;
  animation-fill-mode: both;
}

.fadeInUp {
  animation-name: fadeIn, slideUp;
  animation-duration: 1.5s, 1.5s;
  animation-timing-function: linear, cubic-bezier(0, 0, 0, 1);
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-play-state: running, running;
  animation-delay: 1s, 1s;
  animation-fill-mode: both;
}

.fadeIn {
  animation: 1.5s linear both fadeIn;
}

body {
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
  font-size: 18px;
  position: relative;
}

.hidden {
  display: none;
}

#wheel {
  height: 55px;
  width: 55px;
  z-index: 3;
  filter: drop-shadow(0 0 5px #fff);
  transition: transform .25s linear;
  position: fixed;
  top: 10px;
  left: 10px;
}

@media (max-width: 1000px) {
  #wheel {
    transition: none;
  }
}

#lane {
  width: 0;
  opacity: .5;
  z-index: 1;
  border-left: 2px dotted #000;
  position: absolute;
  top: 36px;
  bottom: 108px;
  left: 36px;
  box-shadow: 0 0 7px 1px #fff;
}

#lane:before, #lane:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  left: -6px;
}

#lane:before {
  top: -5px;
}

#lane:after {
  bottom: -5px;
}

@media (max-width: 1000px) {
  #lane {
    display: none;
  }
}

.section {
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  padding-top: 20px;
  display: flex;
  position: relative;
}

.section .content {
  box-sizing: border-box;
  padding: 60px 30px 60px 90px;
}

@media (max-width: 1000px) {
  .section .content {
    padding: 60px 30px;
  }
}

.raised-section {
  min-height: 80vh;
  width: 100%;
  box-sizing: border-box;
  background-color: #efefef;
  margin: 10vh 0;
  position: relative;
}

.raised-section .title {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: -10vh;
}

@media (max-width: 1000px) {
  .raised-section {
    margin: 15vh 0;
  }

  .raised-section .title {
    top: -15vh;
  }

  .raised-section .title:before {
    display: none;
  }
}

.raised-section:before, .raised-section:after {
  content: "";
  width: 100%;
  height: 10vh;
  z-index: -1;
  filter: drop-shadow(0 0 10px #0006);
  position: absolute;
  left: 0;
}

.raised-section:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 500 500' fill='%23efefef'%3E%3Cpolygon points='0,500 500,0 500,500'/%3E%3C/svg%3E");
  top: calc(2px - 10vh);
}

.raised-section:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 500 500' fill='%23efefef'%3E%3Cpolygon points='0,0 0,500 500,0'/%3E%3C/svg%3E");
  bottom: calc(2px - 10vh);
}

.title {
  text-transform: uppercase;
  letter-spacing: 8px;
  margin: 0;
  padding-left: 90px;
  font-size: 22px;
  position: relative;
}

.title:before {
  content: "";
  width: 10px;
  height: 10px;
  z-index: 2;
  background-color: #979797;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 5px);
  left: 32px;
}

@media (max-width: 1000px) {
  .title {
    text-align: center;
    padding-left: 0;
  }

  .title:before {
    display: none;
  }
}

.audio-book-strip {
  color: #efd48c;
  text-align: center;
  background: #313d49;
  padding: 26px;
}

.webp .section-intro {
  background-image: url("band-promo5.f2366d33.webp");
}

.no-webp .section-intro {
  background-image: url("band-promo5.f5a1558d.jpg");
}

.section-intro {
  height: 140vh;
  background-color: #000;
  background-position: 50% 99.5%;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

.section-intro .logo {
  width: 300px;
  margin: 0;
  padding-top: 90px;
}

.section-intro .subtitle {
  color: #efefef;
  text-transform: uppercase;
  letter-spacing: 10px;
  margin: 0;
  padding-top: 35px;
  font-size: 22px;
  font-weight: 900;
}

@media (max-width: 1000px) and (orientation: portrait) {
  .section-intro {
    height: 90vh;
  }
}

@media (max-width: 823px) and (orientation: landscape) {
  .section-intro {
    height: 165vh;
  }

  .section-intro .logo {
    padding-top: 0;
  }
}

.section-video .content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-video .embed-container {
  width: 70%;
  height: 0;
  background-color: #000;
  border-radius: 6px;
  padding-bottom: 30%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 27px #000;
}

@media (max-width: 1000px) {
  .section-video .content {
    padding-left: 0;
    padding-right: 0;
  }

  .section-video .embed-container {
    height: 0;
    max-width: 100%;
    width: 100%;
    border-radius: 0;
    padding-bottom: 41%;
    position: relative;
    overflow: hidden;
  }
}

.section-video .embed-container iframe, .section-video .embed-container object, .section-video .embed-container embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.section-live {
  padding-top: 40px;
}

.section-live .events {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (max-width: 1200px) {
  .section-live .events {
    align-items: start;
  }
}

@media (max-width: 1000px) {
  .section-live .events {
    align-items: center;
  }
}

.section-live .events .event {
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
  display: flex;
}

.section-live .events .event .name {
  letter-spacing: 6px;
  margin-left: 40px;
  font-weight: 900;
}

.section-live .events .event .country {
  margin-left: 40px;
  font-size: smaller;
}

.section-live .events .event .more-info {
  color: #fff;
  background-color: #000;
  margin-left: 40px;
  padding: 10px;
  font-size: smaller;
  text-decoration: none;
  transition: background-color .3s ease-out;
}

.section-live .events .event .more-info:hover {
  background-color: #61a1a6;
}

@media (max-width: 1000px) {
  .section-live .events .event {
    flex-direction: column;
  }

  .section-live .events .event .name, .section-live .events .event .country, .section-live .events .event .more-info {
    margin-left: 0;
  }

  .section-live .events .event .more-info {
    margin-top: 20px;
  }
}

.section-live .soon, .section-live .gigstarter-button {
  text-align: center;
}

.product-wrapper .product {
  width: 100%;
  min-height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.product-wrapper .product .product-image {
  max-height: 65vh;
  max-width: 100%;
}

.product-wrapper .product .product-ui {
  padding-bottom: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.product-wrapper .product .product-ui .name {
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 30px;
  padding-right: 50px;
  font-size: 20px;
}

.product-wrapper .product .product-ui .description {
  margin-bottom: 30px;
  padding-right: 50px;
}

.product-wrapper .product .product-ui .description p {
  margin: 0 0 7px;
}

.product-wrapper .product .product-ui .buy-button {
  box-sizing: border-box;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #fff;
  box-shadow: none;
  background-color: #61a1a6;
  padding: 15px;
  font-size: 26px;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s ease-out;
  display: block;
  box-shadow: 0 3px 8px #0000003d;
}

.product-wrapper .product .product-ui .buy-button:hover {
  background-color: #42bbc5;
  box-shadow: 0 0 22px #0006;
}

@media (max-width: 1000px) {
  .product-wrapper .product .product-ui {
    padding: 10px 15px 50px;
    position: static;
  }

  .product-wrapper .product .product-ui .name, .product-wrapper .product .product-ui .description {
    padding-right: 0;
  }

  .product-wrapper .product .product-ui .name, .product-wrapper .product .product-ui .description, .product-wrapper .product .product-ui .buy-button {
    text-align: center;
  }
}

.product-wrapper .product .product-ui2 {
  padding: 10px 15px 50px;
}

.product-wrapper .product .product-ui2 .description {
  text-align: center;
  margin-bottom: 30px;
  padding: 10px;
}

.product-wrapper .product .product-ui2 .description p {
  margin: 0 0 7px;
}

.product-wrapper .product .product-ui2 .action-button {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #efd48c;
  box-shadow: none;
  background-color: #353f53;
  border-radius: 99999px;
  padding: 15px;
  font-size: 26px;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s ease-out;
  display: block;
  box-shadow: 0 3px 8px #0000003d;
}

.product-wrapper .product .product-ui2 .action-button:hover {
  background-color: #405f91;
  box-shadow: 0 0 22px #0006;
}

.section-contact .content {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-contact form {
  max-width: 500px;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.section-contact form label {
  text-transform: uppercase;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 900;
}

.section-contact form input, .section-contact form textarea {
  border: 2px solid #000;
  outline: none;
  margin-bottom: 18px;
  padding: 8px;
  font-family: inherit;
  font-size: 18px;
  transition: border .3s ease-in-out;
}

.section-contact form input:focus, .section-contact form textarea:focus {
  border: 2px solid #61a1a6;
}

.section-contact form input:invalid.touched, .section-contact form textarea:invalid.touched {
  border: 2px solid red;
}

.section-contact form textarea {
  resize: vertical;
}

.section-contact form button {
  -webkit-appearance: none;
  appearance: none;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align: center;
  cursor: pointer;
  background-color: #000;
  border: none;
  padding: 8px;
  font-size: 22px;
  font-weight: 400;
  transition: background-color .3s ease-in-out;
}

.section-contact form button[disabled] {
  cursor: not-allowed;
  background-color: #ccc;
}

.section-contact form button:not([disabled]):hover, .section-contact form button:not([disabled]):focus {
  background-color: #61a1a6;
  outline: none;
}

footer {
  background-color: #000;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  display: flex;
  box-shadow: 0 0 22px #0006;
}

footer .social-link {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: inherit;
  color: #fff;
  margin: 0 24px 0 0;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  transition: color .3s ease-out;
  display: flex;
}

footer .social-link:last-child {
  margin: 0;
}

footer .social-link img, footer .social-link svg, footer .social-link object {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

footer .social-link:hover {
  color: #61a1a6;
}

@media (max-width: 1000px) {
  footer {
    flex-direction: column;
  }

  footer .social-link {
    margin: 0 0 16px;
  }

  footer:last-child {
    margin: 0;
  }
}

/*# sourceMappingURL=index.a56689b1.css.map */
